<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="pixinventLink"
      href="#">Predenta - Versiyon {{currentApplicationVersion}}</a>

    <span class="d-none d-sm-inline-block">, Her Hakkı Saklıdır.</span>
  </p>
  <p class="bg-success">
    Lisans Yenileme Tarihi : {{lisansyenileme}}
  </p>
</footer>
<!--Footer Ends-->
